import store from '@/store'
import amlStoreModule from '@/pagsmile/amlStoreModule'
import router from '@/router'

export default function amlJs() {
  const AML_STORE_MODULE_NAME = 'aml'

  // Register module
  if (!store.hasModule(AML_STORE_MODULE_NAME)) store.registerModule(AML_STORE_MODULE_NAME, amlStoreModule)

  const fetchReviewTransactions = (params, success, fail) => {
    store
      .dispatch('aml/fetchReviewTransactions', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const queryJobCount = (success, fail) => {
    store
      .dispatch('aml/queryJobCount')
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const fetchReviewTransactionDetail = (params, success, fail) => {
    store
      .dispatch('aml/fetchReviewTransactionDetail', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const upload = (params, success, fail) => {
    store
      .dispatch('aml/upload', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const saveReviewDetail = (params, success, fail) => {
    store
      .dispatch('aml/saveReviewDetail', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const confirmReviewDetail = (params, success, fail) => {
    store
      .dispatch('aml/confirmReviewDetail', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const queryUserDetail = (params, success, fail) => {
    store
      .dispatch('aml/queryUserDetail', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const queryUserDetailFull = (params, success, fail) => {
    store
      .dispatch('aml/queryUserDetailFull', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const refundConfirmPay = (params, success, fail) => {
    store
      .dispatch('aml/refundConfirmPay', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
  }

  const queryAmlRules = (success, fail) => {
    store
      .dispatch('aml/queryAmlRules')
      .then(response => {
        success(response.data)
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const queryAmlRuleGroups = (success, fail) => {
    store
      .dispatch('aml/queryAmlRuleGroups')
      .then(response => {
        success(response.data)
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const createAmlRuleGroup = (params, success, fail) => {
    store
      .dispatch('aml/createAmlRuleGroup', params)
      .then(response => {
        success(response.data)
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const enableAmlRuleGroup = (gourpId, success, fail) => {
    store
      .dispatch('aml/enableAmlRuleGroup', gourpId)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const disableAmlRuleGroup = (gourpId, success, fail) => {
    store
      .dispatch('aml/disableAmlRuleGroup', gourpId)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const downloadReviewTransactions = (vm, params) => {
    store
      .dispatch('aml/downloadReviewTransactions', params)
      .then(response => {
        const { code } = response.data

        if (code === '10000') {
          vm.$bvToast.toast('Download Review Transactions Request Submitted', {
            title: 'Success',
            icon: 'AlertTriangleIcon',
            variant: 'success',
            toaster: 'b-toaster-top-right',
            solid: true,
          })

          setTimeout(() => {
            router.push({
              name: 'review-transactions-download-log',
            })
          }, 1000)
        }
      })
      .catch(() => {
        vm.$bvToast.toast('Error Download Transactions', {
          title: 'Error',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          toaster: 'b-toaster-top-right',
          solid: true,
        })

        router.push({
          name: 'review-transactions-download-log',
        })
      })
  }

  const fetchReviewDownloadList = (vm, callback) => {
    store
      .dispatch('aml/fetchReviewDownloadList')
      .then(response => {
        const { code, data } = response.data

        if (code === '10000') {
          callback(data)
        }
      })
      .catch(() => {
        vm.$bvToast.toast('Error fetching Download List', {
          title: 'Error',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          toaster: 'b-toaster-top-right',
          solid: true,
        })
      })
  }

  const fetchAllRules = (vm, callback) => {
    store
      .dispatch('aml/fetchAllRules')
      .then(response => {
        const { code, data } = response.data
        const rules = []

        if (code === '10000') {
          data.forEach(item => {
            const rule = { label: item.rule_name, value: item.rule_index }
            rules.push(rule)
          })
        }

        callback(rules)
      })
      .catch(() => {
        vm.$bvToast.toast('Error fetching RiskRule', {
          title: 'Error',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          toaster: 'b-toaster-top-right',
          solid: true,
        })
      })
  }

  return {
    fetchReviewTransactions,
    fetchReviewTransactionDetail,
    queryJobCount,
    upload,
    saveReviewDetail,
    confirmReviewDetail,
    queryUserDetail,
    queryUserDetailFull,
    refundConfirmPay,
    downloadReviewTransactions,
    fetchReviewDownloadList,
    queryAmlRules,
    queryAmlRuleGroups,
    createAmlRuleGroup,
    enableAmlRuleGroup,
    disableAmlRuleGroup,
    fetchAllRules,
  }
}
