<template>
  <div>
    <b-card title="Filter">
      <b-row>
        <b-col
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Type</label>
          <v-select
            v-model="ruleTypeFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="ruleTypeOptions"
            :clearable="false"
            :searchable="false"
            class="w-100"
            :reduce="val => val.value"
          />
        </b-col>

        <b-col
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Status</label>
          <v-select
            v-model="ruleStatusFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="ruleStatusOptions"
            :clearable="false"
            :searchable="false"
            class="w-100"
            :reduce="val => val.value"
          />
        </b-col>

        <b-col
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Name</label>
          <b-form-input
            id="searchKeyInput"
            v-model="searchKey"
            placeholder="Key Word Here..."
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="3"
          class="mb-md-0 mb-2"
        >
          <b-button
            class="mt-2"
            variant="primary"
            @click="doSearch"
          >
            Search
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-card no-body>
      <b-table
        class="position-relative"
        responsive
        :fields="tableFields"
        :items="records"
        primary-key="id"
        show-empty
        striped
        :borderless="false"
        empty-text="No matching records found"
      >
        :filter="searchKey"
        :filter-included-fields="filterOn"
        >

        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveStatusVariant(data.item.status)}`"
          >
            {{ resolveStatusText(data.item.status) }}
          </b-badge>
        </template>

        <template #cell(type)="data">
          {{ resolveRuleType(data.item.type) }}
        </template>

      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BFormInput, BButton, BCard, BRow, BCol, BTable, BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { showToast } from '@/libs/tool'
import useAMLJs from './aml'

const {
  queryAmlRules,
} = useAMLJs()

export default {
  components: {
    BFormInput,
    BButton,
    BCard,
    BRow,
    BCol,
    BTable,
    BBadge,
    vSelect,
  },
  data() {
    return {
      dataMeta: {
        from: 1,
        to: 1,
        of: 0,
      },
      perPage: 10,
      currentPage: 1,
      totalRecords: 0,
      rules: [],
      records: [],
      tableFields: [
        'id',
        'name',
        'type',
        { key: 'check_value', lable: 'target' },
        { key: 'duration_type', lable: 'duration_type' },
        { key: 'duration_value', lable: 'duration_value' },
        'action',
        'status',
      ],
      ruleTypeFilter: '',
      ruleTypeOptions: [
        { label: 'All', value: '' },
        { label: 'QUOTA LIMIT', value: 101 },
        { label: 'FREQUENCY LIMIT', value: 201 },
      ],
      ruleStatusFilter: '',
      ruleStatusOptions: [
        { label: 'All', value: '' },
        { label: 'Activated', value: true },
        { label: 'Deactivated', value: false },
      ],
      searchKey: '',
      filterOn: ['name'],
    }
  },
  created() {
    this.queryRules()
  },
  methods: {
    queryRules() {
      queryAmlRules(
        records => {
          this.rules = records
          this.records = records
        },
        fail => {
          showToast(this, 'Warning', `fetch list with ${fail}.`)
        },
      )
    },
    resolveStatusVariant(status) {
      if (status) {
        return 'success'
      }
      return 'danger'
    },
    resolveStatusText(status) {
      if (status) {
        return 'Activated'
      }
      return 'Deactivated'
    },
    resolveRuleType(type) {
      if (type === 101) {
        return 'Quota Limit'
      }
      if (type === 201) {
        return 'Frequency Limit'
      }
      return 'Unknown'
    },
    doSearch() {
      this.records = this.rules
      if (this.ruleTypeFilter !== '') {
        this.records = this.records.filter(p => p.type === this.ruleTypeFilter)
      }
      if (this.ruleStatusFilter !== '') {
        this.records = this.records.filter(p => p.status === this.ruleStatusFilter)
      }
      if (this.searchKey !== '') {
        // eslint-disable-next-line no-undef
        this.records = this.records.filter(p => p.name.indexOf(this.searchKey) > 0)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
