import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // fetch review transactions
    fetchReviewTransactions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/aml/review/list', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    // fetch review transaction detail
    fetchReviewTransactionDetail(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/aml/review/detail', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    // query job count
    queryJobCount() {
      return new Promise((resolve, reject) => {
        axios.post('/aml/review/jobcount')
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    // idwall user detail query
    queryUserDetail(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/aml/review/userdetail?id_type=${params.idType}&id_number=${params.idNumber}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    // idwall user detail query
    queryUserDetailFull(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/aml/review/userdetailfull?id_type=${params.idType}&id_number=${params.idNumber}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    // review refund confirm
    refundConfirmPay(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/aml/review/refundpay', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    // upload files
    upload(ctx, params) {
      const header = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      return new Promise((resolve, reject) => {
        axios.post('/aml/review/uploadfiles', params, header)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    // transactions review save API
    saveReviewDetail(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/aml/review/reviewdetail/save?trade_no=${params.tradeNo}&review_status=${params.reviewStatus}&desc=${params.desc}&files=${params.files}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    // transactions review confirm API
    confirmReviewDetail(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/aml/review/reviewdetail/confirm?trade_no=${params.tradeNo}&review_status=${params.reviewStatus}&out_trade_no=${params.outTradeNo}&app_id=${params.appId}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    // cpf 验证
    verifyCPFId(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/risk/verifyId', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    verifyIdwallInfo(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(params.url, params.idwallInfoList)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    // cpf 下载
    downloadAmlIdList(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/risk/downloadIdInfo', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    // cpf 查询
    fetchAmlIdList(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/risk/listIdInfo', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    // 保存反洗钱
    saveIdAml(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/risk/saveIdAml', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    // 反洗钱黑名单下载
    downloadAmlBlackList(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/risk/downloadAmlInfo', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    // 反洗钱黑名单
    fetchAmlBlackList(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/risk/listIdAml', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    // cpf 详细
    fetchIdInfo(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/risk/selectIdInfo', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    // 确认制裁信息
    confirmSanction(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/risk/sanction/confirm', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    // cpf 详细-交易记录
    fetchOrderIdInfoList(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/transaction/listIdInfoOrder', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    // 黑名单历史交易记录
    fetchOrderIdList(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/transaction/listOrderById', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    // 反洗钱下载列表
    fetchAmlDownloadList(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/risk/downloadList', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    // 取消反洗钱黑名单
    deleteIdAml(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/risk/deleteIdAml', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    // 查询规则
    queryAmlRules() {
      return new Promise((resolve, reject) => {
        // axios.post('http://192.168.10.55/mock/73/admin/risk/rule/listRule')
        axios.post('/risk/rule/listRule')
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    // 查询规则组
    queryAmlRuleGroups() {
      return new Promise((resolve, reject) => {
        // axios.post('http://192.168.10.55/mock/73/admin/risk/rule/listGroup')
        axios.post('/risk/rule/listGroup')
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    // 创建规则组
    createAmlRuleGroup(ctx, params) {
      return new Promise((resolve, reject) => {
        // axios.post('http://192.168.10.55/mock/73/admin/risk/rule/createGroup', params)
        axios.post('/risk/rule/createGroup', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    // 启用规则组
    enableAmlRuleGroup(ctx, groupId) {
      return new Promise((resolve, reject) => {
        // axios.post(`http://192.168.10.55/mock/73/admin/risk/rule/enableGroup?rule_group_id=${groupId}`)
        axios.post(`/risk/rule/enableGroup?rule_group_id=${groupId}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    // 禁用规则组
    disableAmlRuleGroup(ctx, groupId) {
      return new Promise((resolve, reject) => {
        // axios.post(`http://192.168.10.55/mock/73/admin/risk/rule/disableGroup?rule_group_id=${groupId}`)
        axios.post(`/risk/rule/disableGroup?rule_group_id=${groupId}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    // 下载订单请求
    downloadReviewTransactions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/aml/review/download?date_mode=${params.date_mode}&search_mode=${params.search_mode}&search_key=${params.search_key}&zone_id=${params.zone_id}`, params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    // 获取订单下载记录
    fetchReviewDownloadList() {
      return new Promise((resolve, reject) => {
        axios.post('/aml/review/download-list')
          .then(response => {
            if (response.data.code === '10000') {
              resolve(response)
            }
          })
          .catch(error => { reject(error) })
      })
    },

    fetchAllRules() {
      return new Promise((resolve, reject) => {
        axios.post('/risk/rule/getAllRules')
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
  },
}
